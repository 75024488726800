import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import YAMLData from '../../../data/solutions/education.yaml'

export default function FolderList() {

  return (
    <List sx={{ width: '100%' }}>
      {YAMLData.benefits.map((benefit) => <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={benefit.name} primaryTypographyProps={{ fontSize: '22px' }} secondary={benefit.description} secondaryTypographyProps={{ fontSize: '18px', color: 'black' }} />
      </ListItem>)}
    </List>
  );
}
